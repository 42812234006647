import { Box, Button, Grid, Link, Typography } from '@mui/material'

type Props = {
  redirect?: string
}

export function LoginPage(props: Props) {
  let url = '/api/auth/google'
  if (props.redirect) {
    url += `?redirect=${encodeURIComponent(props.redirect)}`
  }

  return (
    <Box sx={{ flexGrow: 1 }} height={'100vh'}>
      <Grid container spacing={4} direction="column" justifyContent="center" alignItems="center" height={'100%'}>
        <Grid item>
          <Typography variant="h3">👋</Typography>
        </Grid>
        <Grid item>
          <Button LinkComponent={Link} size={'large'} href={url} variant={'outlined'}>
            Login
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}
