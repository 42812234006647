import type { GolfCourse, Match, User } from '@prisma/client'
import type { GetServerSideProps } from 'next'
import { generateMatch } from './api/match'
import { Alert, Box, Button, Grid, Link, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { LoginPage } from '../components/LoginPage'
import { getAuthenticatedUser } from '../lib/GoogleAuth'
import { prisma } from '../lib/Database'
import { MatchSchema, VoteApiResponseSchema, type VoteApiResponse } from '../lib/Schema'
import Head from 'next/head'

type MatchCourse = Pick<GolfCourse, 'name' | 'location' | 'id'>
type LoggedOutProps = { user: null; match: null; usePrefetch: boolean }
type LoggedInProps = {
  match: [MatchCourse, MatchCourse]
  user: User
  votesCount: number
  potentialMatchesCount: number
  usePrefetch: boolean
}

type Props = LoggedInProps | LoggedOutProps

export const getServerSideProps = (async params => {
  const user = await getAuthenticatedUser(params.req)
  if (!user) {
    return { props: { user: null, match: null, usePrefetch: false } }
  }

  const votesCount = await prisma.match.count()
  const coursesCount = await prisma.golfCourse.count()
  const potentialMatchesCount = coursesCount * (coursesCount - 1)

  const match = await generateMatch(user)
  return { props: { match, user, votesCount, potentialMatchesCount, usePrefetch: true } }
}) satisfies GetServerSideProps<Props>

export default function IndexPage(props: Props) {
  const [match, setMatch] = useState(props.match)
  const [response, setResponse] = useState<VoteApiResponse | null>(null)
  const [voteInFlight, setVoteInFlight] = useState(false)
  const [votesCount, setVotesCount] = useState('votesCount' in props ? props.votesCount : 0)
  const [error, setError] = useState<string | null>(null)
  const [prefetchedNextMatch, setPrefetchedNextMatch] = useState<typeof props.match | null>(null)

  useEffect(() => {
    if (props.user?.id && props.usePrefetch) {
      fetch('/api/match')
        .then(r => r.json())
        .then(r => MatchSchema.parse(r))
        .then(r => setPrefetchedNextMatch(r))
        .catch(e => console.error(e))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.[0].id, match?.[1].id, props.usePrefetch, props.user?.id])

  if (!props.user) {
    return <LoginPage />
  }

  return (
    <>
      <Head>
        <title>Elo Ranking - Vote</title>
      </Head>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'} style={{ height: '100%' }}>
          <Stack direction={'column'} spacing={5}>
            {error ? <Alert severity="error">{error}</Alert> : null}
            <Button variant="text" LinkComponent={Link} href="/leaderboard">
              Leaderboard
            </Button>
            <Typography>
              {new Intl.NumberFormat().format(votesCount)} votes out of{' '}
              {new Intl.NumberFormat().format(props.potentialMatchesCount)} potential matches
            </Typography>
            <Stack direction={'column'} spacing={5}>
              {match?.map(course => {
                return (
                  <Button
                    disabled={voteInFlight}
                    onClick={() => {
                      const vote: Pick<Match, 'loser_course_id' | 'voter_user_id' | 'winner_course_id'> = {
                        voter_user_id: props.user.id,
                        winner_course_id: course.id,
                        loser_course_id: match.find(c => c.id !== course.id)?.id ?? course.id,
                      }

                      let alreadySetNextMatch = false
                      if (prefetchedNextMatch) {
                        console.log('Setting prefetched next match', { prefetchedNextMatch })
                        setMatch(prefetchedNextMatch)
                        setPrefetchedNextMatch(null)
                        alreadySetNextMatch = true
                      }

                      if (!alreadySetNextMatch) {
                        setVoteInFlight(true)
                      }

                      fetch('/api/vote', {
                        method: 'POST',
                        headers: { 'content-type': 'application/json' },
                        body: JSON.stringify(vote),
                      })
                        .then(r => r.json())
                        .then(r => {
                          const voteResponse = VoteApiResponseSchema.safeParse(r)
                          if (!voteResponse.success) {
                            console.error(voteResponse.error)
                            console.error(r)
                            setError(voteResponse.error.message)
                            return
                          }

                          setResponse(voteResponse.data)
                          setVotesCount(voteResponse.data.count)
                          if (!alreadySetNextMatch) {
                            setMatch(voteResponse.data.nextMatch)
                          }
                          setVoteInFlight(false)
                        })
                        .catch(err => {
                          console.error(err)
                          setError(String(err))
                        })
                    }}
                    variant="outlined"
                    key={course.name + course.location}
                  >
                    <Typography textTransform={'none'} fontSize={24}>
                      {course.name} ({course.location})
                    </Typography>
                  </Button>
                )
              })}
            </Stack>

            {response ? (
              <section>
                <p>
                  {response.winner.name} ({response.winner.location}): {response.winner.elo_rating} (+
                  {response.ratingDelta})
                </p>
                <p>
                  {response.loser.name} ({response.loser.location}): {response.loser.elo_rating} (-
                  {response.ratingDelta})
                </p>
              </section>
            ) : null}
          </Stack>
        </Grid>
      </Box>
    </>
  )
}
