import { z } from 'zod'
import * as zod from '../../prisma/zod'

export const GolfCourseSelectSchema = zod.GolfCourseModel.pick({ name: true, location: true, id: true })

// Elo
export const MatchResultSchema = z.object({
  match: zod.MatchModel.omit({ created_at: true }),
  winner: zod.GolfCourseModel.omit({ created_at: true }),
  loser: zod.GolfCourseModel.omit({ created_at: true }),
  count: z.number(),
  ratingDelta: z.number(),
})

export type MatchResult = z.infer<typeof MatchResultSchema>

// Match API
export type GolfCourseSelect = z.infer<typeof GolfCourseSelectSchema>
export const MatchSchema = z.tuple([GolfCourseSelectSchema, GolfCourseSelectSchema])
export type Match = z.infer<typeof MatchSchema>

// Vote API
export const VoteApiResponseSchema = MatchResultSchema.merge(z.object({ nextMatch: MatchSchema }))
export type VoteApiResponse = z.infer<typeof VoteApiResponseSchema>
