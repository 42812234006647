import * as z from "zod"
import { CompleteMatch, RelatedMatchModel, CompleteMatchCandidates, RelatedMatchCandidatesModel } from "./index"

export const GolfCourseModel = z.object({
  id: z.number().int(),
  name: z.string(),
  location: z.string(),
  years_played: z.string(),
  architects: z.string(),
  elo_rating: z.number().int(),
  created_at: z.date(),
})

export interface CompleteGolfCourse extends z.infer<typeof GolfCourseModel> {
  losing_matches: CompleteMatch[]
  winning_matches: CompleteMatch[]
  match_candidates_1: CompleteMatchCandidates[]
  match_candidates_2: CompleteMatchCandidates[]
}

/**
 * RelatedGolfCourseModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedGolfCourseModel: z.ZodSchema<CompleteGolfCourse> = z.lazy(() => GolfCourseModel.extend({
  losing_matches: RelatedMatchModel.array(),
  winning_matches: RelatedMatchModel.array(),
  match_candidates_1: RelatedMatchCandidatesModel.array(),
  match_candidates_2: RelatedMatchCandidatesModel.array(),
}))
