import * as z from "zod"
import { CompleteMatch, RelatedMatchModel, CompleteMatchCandidates, RelatedMatchCandidatesModel } from "./index"

export const UserModel = z.object({
  id: z.number().int(),
  email: z.string(),
})

export interface CompleteUser extends z.infer<typeof UserModel> {
  matches: CompleteMatch[]
  match_candidates: CompleteMatchCandidates[]
}

/**
 * RelatedUserModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedUserModel: z.ZodSchema<CompleteUser> = z.lazy(() => UserModel.extend({
  matches: RelatedMatchModel.array(),
  match_candidates: RelatedMatchCandidatesModel.array(),
}))
