import * as z from "zod"
import { CompleteGolfCourse, RelatedGolfCourseModel, CompleteUser, RelatedUserModel } from "./index"

export const MatchModel = z.object({
  id: z.number().int(),
  winner_course_id: z.number().int(),
  loser_course_id: z.number().int(),
  voter_user_id: z.number().int(),
  created_at: z.date(),
})

export interface CompleteMatch extends z.infer<typeof MatchModel> {
  losing_golf_course: CompleteGolfCourse
  winning_golf_course: CompleteGolfCourse
  user: CompleteUser
}

/**
 * RelatedMatchModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedMatchModel: z.ZodSchema<CompleteMatch> = z.lazy(() => MatchModel.extend({
  losing_golf_course: RelatedGolfCourseModel,
  winning_golf_course: RelatedGolfCourseModel,
  user: RelatedUserModel,
}))
