import * as z from "zod"
import { CompleteUser, RelatedUserModel, CompleteGolfCourse, RelatedGolfCourseModel } from "./index"

export const MatchCandidatesModel = z.object({
  id: z.number().int(),
  voter_user_id: z.number().int(),
  course_id_1: z.number().int(),
  course_id_2: z.number().int(),
})

export interface CompleteMatchCandidates extends z.infer<typeof MatchCandidatesModel> {
  user: CompleteUser
  golf_course_1: CompleteGolfCourse
  golf_course_2: CompleteGolfCourse
}

/**
 * RelatedMatchCandidatesModel contains all relations on your model in addition to the scalars
 *
 * NOTE: Lazy required in case of potential circular dependencies within schema
 */
export const RelatedMatchCandidatesModel: z.ZodSchema<CompleteMatchCandidates> = z.lazy(() => MatchCandidatesModel.extend({
  user: RelatedUserModel,
  golf_course_1: RelatedGolfCourseModel,
  golf_course_2: RelatedGolfCourseModel,
}))
